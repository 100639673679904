import React from 'react';
import BG2 from '../../assets/BG1_2.jpg';


//changes-done
export const HeroSection = () => {
  return (
    <>
      <div
        className="w-full  flex flex-col justify-end  items-center md:h-[600px] h-[200px]  bg-cover   bg-center"
        style={{ backgroundImage: `url(${BG2})` }}
      >
        
        <div className="text-white  flex  md:flex-row flex-col ">
            <div className="leading-tight border-0 md:ml-0 ml-24  md:mb-3 mb-0  md:border-r-[3px] pr-6">
      <h1 className="font-bold text-[1.3rem]  md:ml-40 ml-0 md:mt-4 mt-0 md:text-white  md:text-5xl">POLLFIRST</h1>
      <h2 className="md:text-lg ml-1  md:text-white  text-[0.7rem] text-nowrap md:ml-[14rem] md:mt-[-0.5rem]">The Science of Politics</h2>
      </div>


      <h2 className='lg:w-[33rem]  w-auto  leading-tight    ml-3  my-5 text-md md:text-[1.2rem]'>Evolving for the last 25 years, building strategies, refining how we use data, research &  tech to deliver the only thing that matters-<strong> winning​  </strong></h2>
     
    </div>

    

      </div>
    </>
  );
};
