import React from 'react'
import { Navbar } from '../../Components/Navbar'
import { HeroSection } from '../../Components/HeroSection'
import { CampaignManagement } from '../../Components/CampaignManagement'
import {Banner} from './../../Components/Banner'
export const Home = () => {
   


  return (
    <>
        {/* <Navbar/> */}
        <HeroSection/>
        <CampaignManagement/>
        <Banner/> 
        <div>
        <div>
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header> */}
      <div id="mainDiv" className="w-full">
        {/* START-SECTION 1 */}
        <div class="flex flex-col md:flex-row gap-10 p-4">
          {/* <!-- Left Side - Paragraph --> */}
          {/* Left Side - Paragraph */}
          <div class="flex-1 text-left relative">
            <h1 className="text-3xl text-center font-bold mb-5 font-poppins">
              VOTER RELATIONSHIP MGMT SYSTEM
            </h1>

            <p className="text-[20px] mb-5 font-poppins">
              Winning an election is the beginning of a long relationship with
              the voters of the constituency. This relationship would be tested
              in the next election, when you go out and seek support of the
              people.
            </p>
            <p className="text-[20px] mb-5 font-poppins">
              Our Voter Relationship Management System (VRMS) factors in the
              political exigencies, but also seeks to play a positive role of
              building a relationship with the voters of the area. The focus is
              on ensuring that the next elections are easier to navigate.
            </p>

            <p className="text-[20px] mb-5 font-poppins">
              From connecting with them, and also ensuring that the benefits of
              government schemes reach beneficiaries, the VRMS provides the
              last-mile connectivity with the voter.
            </p>

            {/* Wrapper for the "Know More" text */}
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
              <p className="text-[20px] font-poppins">Know more</p>
            </div>
          </div>

          {/* <!-- Right Side - Image --> */}
          <div class="flex-1">
            <img
              src="./Constituency.jpg"
              alt="Placeholder image"
              class="w-full h-auto"
            />
          </div>
        </div>
        {/* END-SECTION 1 */}

        {/* START-SECTION 2 */}
        <div class="grid grid-cols-1 md:grid-cols-3 gap-6 p-4">
          {/* <!-- Column 1 --> */}
          <div class="bg-white p-6 text-center border-r-4 border-purple-500">
            <h2 class="text-3xl  mb-2 font-bold font-poppins">
              THE POLLFIRST ADVANTAGE
            </h2>
            <p class="text-gray-700 mb-4 font-poppins">
              Each campaign & client is different, strategies have to change
              when the social context changes. We know what moves the needle.
            </p>
            <a
              href="#"
              class="text-black font-bold text-xl uppercase underline font-poppins"
            >
              Know More
            </a>
          </div>

          {/* <!-- Column 2 --> */}
          <div class="bg-white p-6 text-center border-r-4 border-purple-500 flex flex-col items-center">
            <img
              src="./adfirst.png"
              alt="Adfirst image"
              class="mb-4" // Adds margin below the image
            />
            <p class="text-gray-700 mb-4 font-poppins">
              Digital Targeting, Redefined. Adfirst is India's finest digital
              targeting engine that helps you reach your target voters at the
              booth level.
            </p>
            <a
              href="#"
              class="text-black font-bold text-xl uppercase underline font-poppins"
            >
              Know More
            </a>
          </div>

          {/* <!-- Column 3 (No border) --> */}
          <div class="bg-white p-6 text-center flex flex-col items-center">
            <img
              src="./brandscore.png"
              alt="Brandscore image"
              class="mb-4" // Adds margin below the image
            />
            <p class="text-gray-700 mb-4 font-poppins">
              Brandscore is an AI-driven brand tracking tool which tracks how
              your brand is performing vis-à-vis your competition.
            </p>
            <a
              href="#"
              class="text-black font-bold text-xl uppercase underline font-poppins"
            >
              Know More
            </a>
          </div>
        </div>

        {/* END-SECTION 2 */}
        {/* START-SECTION 3 */}
        <div class="flex flex-col md:flex-row gap-10 p-4">
          {/* <!-- Left Side - Paragraph --> */}
          {/* Left Side - Paragraph */}
          <div class="flex-1 text-left">
            <img
              src="./Branding.jpg"
              alt="Placeholder image"
              class="w-full h-auto"
            />
          </div>

          {/* <!-- Right Side - Image --> */}
          <div class="flex-1  relative">
            <h1 className="text-3xl text-center font-bold mb-5 font-poppins">
              LEADERSHIP BRANDING
            </h1>

            <p className="text-[20px] mb-5 font-poppins">
              Leaders need to change the way they brand themselves; to change
              the way people see them.
            </p>
            <p className="text-[20px] mb-5 font-poppins">
              Branding in an ideas game and technology plays a big part in
              identifying both problem areas, & new opportunities. It is also
              about striking the right balance, and not losing credibility.
            </p>

            <p className="text-[20px] mb-5 font-poppins">
              Built on our 7–attributes system, our Leadership Branding system
              first evaluate where you stand today, and the brand goals you wish
              to achieve.
            </p>

            <p className="text-[20px] mb-5 font-poppins">
              With our AI-powered perception measurement and content generation
              system, the focus would be on always being awake to new
              opportunities.
            </p>

            {/* Wrapper for the "Know More" text */}
            <div className="absolute bottom-0 right-0 mb-4 mr-4">
              <p className="text-[20px] font-poppins">Know more</p>
            </div>
          </div>
        </div>
        {/* END-SECTION 3 */}
        {/* START-SECTION 4 */}
        <div
          className="relative bg-cover  md:h-auto h-[700px] bg-center h-[450px] p-8 flex flex-col justify-between text-white"
          style={{ backgroundImage: "url('./Tracker_polls_BG.jpg')" }} // Update with your image path
        >
          <div className="md:flex-row flex-col flex w-full h-full">
            {/* Left Side Content */}
            <div className="md:w-1/2 w-full flex flex-col justify-center md:pl-10 pl-0 md:pt-20 pt-2 pb-10">
              <h1 className="md:text-7xl text-4xl text-nowrap font-bold mb-4">TRACKER POLLS</h1>
              <p className="mb-4   md:text-[20px] text-md">
                Can you drive a car without a speedometer. The stakes are too
                high when you are trying to fight an election without real time
                data on the ground situation.
              </p>
              <p className="mb-4 md:text-[20px] text-md">
                Our Trackers give you real time data on political trends at the
                constituency level, giving you actionable insights to act on.
              </p>
              <a
                href="#"
                className="text-purple-200 font-bold hover:underline self-end text-2xl"
              >
                Know More
              </a>
            </div>

            <div className="md:w-1/2 w-full md:flex block justify-center items-center">
              <img
                src="orgtrackerpolls.png"
                alt="Placeholder image"
                className="max-w-full  md:mt-0 mt-12 h-auto"
              />
            </div>
          </div>
        </div>

      </div>
  </div>
  </div>


     
    
    </>


  )
}
