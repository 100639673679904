import React from 'react'
import BG2 from '../../assets/BG2.jpg'
import person from '../../assets/Person_imageA.png'

export const Banner = () => {
  return (
    <>
    
    <div
        className="w-full flex md:flex-row flex-col font-poppins  md:px-20 px-2  md:mt-0 mt-5  justify-between  bg-cover   bg-center"
        style={{ backgroundImage: `url(${BG2})` }}
      >
        
        <div className="w-1/2  mt-24">
        <h1  className='font-poppins  text-[32px]  text-white text-wrap   font-semibold'>UNDERSTANDING THE</h1>
        
        <h1  className='font-poppins  text-[54px]  text-white  md:text-nowrap text-wrap font-bold'>POLITICAL BRAIN</h1>

        <h1 className='text-white  font-poppins text-wrap  text-[18px] md:w-[40rem] w-[18rem]'>Voting choice is the final outcome. We have spent years understanding the cognitive biases and processes that shape political decision making. ​

Unlocking the political brain is the key to shifts in political behaviour.​</h1>
        </div>

        <div className="lg:w-1/4 w-auto md:ml-[200px] md:ml-12 ml-8 mt-[30px]  ">
          <img src={person} className='lg:w-[14rem]  w-[10rem]   m-10' alt="" />
        </div>



      </div>
    
    </>
  )
}
