// App.js
import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { Navbar } from "./Components/Navbar";
import { Home } from "./Pages/Home";
import  {Team} from "./Pages/Team";
import { Services } from "./Pages/Services";
import {Ethics}  from "./Pages/Ethics";
import  Contact  from "./Pages/Contact";
import Footer from "./Components/Footer";
import "./App.css";

const AppLayout = () => {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer/>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/team", element: <Team /> },
      { path: "/services", element: <Services /> },
      { path: "/ethics", element: <Ethics /> },
      { path: "/contact", element: <Contact /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
