import React from 'react';
import { FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';
import pollfirst_logo from '../../assets/logo.png';
import { PiFacebookLogo } from "react-icons/pi";
import { TiSocialTwitterCircular } from "react-icons/ti";

const Footer = () => {
  return (
    <>
      <div className="bg-[#0B162c] lg:pt-20 lg:pb-20 pb-5 pt-5 font-montesarrat"> {/* Background color */}
        <div className="container mx-auto px-6 md:px-28 pt-20 pb-10">
          <div className="flex flex-col md:flex-row justify-between px-4">

            {/* Footer Widget Start */}
            <div className="footer-widget pb-4 border-b-[0.001px] border-[rgba(255,255,255,0.2)] text-left mb-12 md:mb-0 md:w-1/3"> {/* Set to 1/3 width */}
              <h3 className="text-white font-bold text-[22px] mb-4">
                CONTACT <span className="text-[#099A40] font-bold">US</span>
              </h3>
              <ul className="text-white md:text-[16px] text-[14px] space-y-2">
                <li><span>Phone:</span> <a href="tel:+919811212248" className="font-thin hover:underline">+91 9811212248</a></li>
                <li><span>Email:</span> <a href="mailto:info@pollfirst.in" className="font-thin hover:underline">info@pollfirst.in</a></li>
                <li><span>Address:</span> <span className="font-thin">C-1, Sector-15, <br /> Noida, Uttar Pradesh - 201301</span></li>
              </ul>
            </div> {/* Footer Widget End */}

            {/* Footer Widget Start */}
            <div className="footer-widget  md:border-l-[0.001px] md:border-r-[0.001px] border-l-0 border-r-0 border-[rgba(255,255,255,0.2)] text-center mb-10 md:mb-0 md:w-1/3"> {/* Set to 1/3 width */}
              <div className="">
                <img src={pollfirst_logo} alt="Logo" className="mb-4 mx-auto" />
                <p className="text-white text-nowrap">
                  <a href="#" className="text-white hover:underline">Terms &amp; Conditions</a> | 
                  <a href="#" className="text-white hover:underline"> Privacy &amp; Policy</a>
                </p>
              </div>
            </div> {/* Footer Widget End */}

            {/* Footer Widget Start */}
            <div className="footer-widget text-right md:w-1/3"> {/* Set to 1/3 width */}
              <h3 className="text-[22px] font-bold text-center mb-4 text-white">
                FOLLOW <span className="font-bold text-[#099A40]">US</span>
              </h3>
              <div className="footer-social flex justify-center space-x-4">
                <a href="https://www.facebook.com/pollfirstin/" className="text-white hover:text-[#099A40]"><PiFacebookLogo className='text-[30px] my-[5px]' /></a>
                <a href="https://www.twitter.com/pollfirstin" className="text-white hover:text-[#099A40]"><TiSocialTwitterCircular className='text-[30px] my-[5px]' /></a>
                <a href="https://www.instagram.com/pollfirstin" className="text-white hover:text-[#099A40]"><FaInstagram className='text-[30px] my-[5px]' /></a>
                <a href="https://www.youtube.com/pollfirstin" className="text-white hover:text-[#099A40]"><FaYoutube className='text-[30px] my-[5px]' /></a>
              </div>
            </div> {/* Footer Widget End */}

          </div>
        </div>
      </div>

      <div className="bg-green-600 font-montesarrat text-center py-4">
        <p className="text-white text-[14px]">
          © {new Date().getFullYear()} Copyright © 2022, All Rights Reserved by POLLFIRST.
        </p>
      </div>
    </>
  );
};

export default Footer;
