import React from 'react';
import { Link } from 'react-router-dom';
import Service1 from '../../../assets/Services/Service1.jpg';

export const ServiceCard = ({ heading, text, link }) => {
  return (
    <div className="lg:mx-4 flex lg:mt-6 mt-5 md:m-3 lg:m-0 relative text-white font-poppins justify-center items-center group w-[350px] h-[320px] overflow-hidden"> {/* Fixed size and overflow hidden */}


      <img 
        className="w-full h-full transform transition-transform duration-300 group-hover:scale-110" // Use full width and height
        src={Service1} 
        alt="Service" 
      />
      
      <div className="bg-black bg-opacity-70 absolute inset-0"></div>

      <div className="absolute mx-5 space-y-3">
        <h1 className="font-semibold hover:underline text-[22px]">{heading}</h1>
        <h2 className="text-[14px] w-[95%]">{text}</h2>
        <br />
    
        <Link 
          to={link} 
          className="bg-[#099A40] lg:text-[14px] text-[12px] rounded-sm font-medium hover:bg-[rgba(255,255,255,0.01)] font-poppins md:px-8 px-8 py-3 mt-4 inline-block"
        >
          LEARN MORE
        </Link>
      </div>
    </div>
  );
};
