import React from 'react'

import TeamBanner from '../../assets/Teams/TeamBannerBg.jpg'


const Ethicsdetails=[{heading:"01. First, do no harm",text:"As people who understand how human-beings think, we have to be always mindful of the fact that our actions will have consequences. We remain deeply committed to our internal code of ethics and will not do anything that could lead to negative consequences for society at large"},
                     {heading:"02. Clients come first",text:"A company remains in business only as long as it adds value to the clients and delivers on what it promises. While we aspire to exceed client expectations, we don’t overcommit. We aspire to have long associations with our clients who place their faith in us."},
                     {
                        heading:"03. Respect Privacy",
                        text:"At Pollfirst, we deeply value the trust our respondents place in us by sharing their perceptions and we shall not share this data with clients or third parties. We follow the highest standards of privacy while dealing with online data and ensure privacy of all our respondents."
                     }
                     
]
export const Ethics = () => {
  return (
    <>
    
    
<div
      className=" h-[250px] flex items-center justify-between section bg-cover  bg-center-bottom-20 relative"
      style={{ backgroundImage: `url(${TeamBanner})` }}
    >
      <div className="bg-black bg-opacity-70 absolute inset-0"></div>
      <div className="w-full relative z-10">
        <div className=" w-full row flex justify-around items-center">
          
          <div className="">
            <h1 className="text-white text-4xl font-bold">Ethics</h1>
          </div>

          <div className="">
            <ul className=" flex space-x-2 text-white text-lg">
              <li>
                <a href="#" className="hover:underline">
                  Home
                </a>
              </li>
              <li className="active">/ Ethics</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="container  lg:mx-auto mx-0 mt-32  font-poppins ">
        <h1 className='font-poppins text-[#404040] font-bold text-center mb-2 text-[36px]'>OUR CODE OF ETHICS</h1>
        <h1 className='font-poppins text-center font-thin md:px-[12px] leading-relaxed text-[#666666] lg:w-[40rem] w-full  mx-auto  text-center text-[16px]'> Our strong ethics are at the core of what we do. As individuals, we understand that our actions have consequences 
            and we are morally obliged to understand that  As a company, we are committed to delivering value 
            to our clients  above all, we are obligated to contribute to the overall well-being of </h1>
    </div>
    <div className="font-poppins container mx-auto mt-20 flex flex-col items-center">
  {Ethicsdetails.map((item, index) => (
    <div key={index} className="w-full lg:max-w-[70%] lg:max-w-[50%] flex flex-col items-start mb-6">
      <h1 className="text-[#404040] font-bold text-[24px] self-start ml-4 lg:ml-4 mb-4">{item.heading}</h1>
      <h1 className="text-[14px] text-[#666666] font-thin px-4 text-left w-full">{item.text}</h1>
    </div>
  ))}
</div>







    </>
  )
}
