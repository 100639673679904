import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

import TeamBanner from '../../assets/Teams/TeamBannerBg.jpg'
const ContactForm = () => {
  return (
    <>
    
    <div
      className=" h-[250px] flex items-center justify-between section bg-cover  bg-center-bottom-20 relative"
      style={{ backgroundImage: `url(${TeamBanner})` }}
    >
      <div className="bg-black bg-opacity-70 absolute inset-0"></div>
      <div className="w-full relative z-10">
        <div className=" w-full row flex justify-around items-center">
          
          <div className="">
            <h1 className="text-white text-4xl font-bold">Contact Us</h1>
          </div>

          <div className="">
            <ul className=" flex space-x-2 text-white text-lg">
              <li>
                <a href="#" className="hover:underline">
                  Home
                </a>
              </li>
              <li className="active">/ Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container font-poppins mx-auto px-4 mt-10">
      <h2 className="text-center text-[36px] font-poppins font-bold mb-6 text-[#404040]">Get In Touch With Our Team</h2>
      <h1 className="text-center font-thin  mb-8 ">Let us tailor a service package that meets your needs.  Tell us a little about your business, <br/>and we will get back to you with some ideas as soon as possible.</h1>
      <div className="flex justify-center mt-10"> {/* Container to center the button */}
      <a
        href="https://wa.me/919811212248"
        target="_blank"
        rel="noopener noreferrer" // Recommended for security
        className="bg-[#20aa20] text-center text-white font-montesarrat md:text-[20px] text-[16px] font-medium inline-flex items-center px-4 py-2 rounded-sm  transition duration-300 hover:bg-green-600"
      >
        <FaWhatsapp className="text-xl  mr-2" /> {/* WhatsApp icon */}
        MESSAGE US ON WHATSAPP
      </a>
    </div> 
      <div className="text-center flex flex-col gap-10 mt-6 mb-7">
        <p className="text-[#666666]">Monday - Saturday: 9am - 7pm</p>
        <p className="text-[#666666]">Saturday - Sunday: Available on Chat</p>
      </div>
      <form className="flex flex-col items-center">
        <div className="flex flex-wrap gap-4 justify-center w-full">
          {/* Name Input */}
          <div className="w-full md:w-[30%] mb-4">
            <input 
              type="text" 
              name="name" 
              placeholder="Name" 
              className="w-full p-3 bg-[#FAFAFA]  rounded-md focus:outline-none focus:border-[#099A40]"
              required
            />
          </div>

          {/* Email Input */}
          <div className="w-full md:w-[30%] mb-4">
            <input 
              type="email" 
              name="email" 
              placeholder="Email" 
              className="w-full p-3 bg-[#FAFAFA]  rounded-md focus:outline-none focus:border-[#099A40]"
              required
            />
          </div>
        </div>

        {/* Message Textarea */}
        <div className="w-full md:w-[70%] mb-4">
  <textarea 
    name="message" 
    placeholder="Message" 
    className="w-full 2xl:w-[36vw] lg:w-[88%] 2xl:min-w-[88%] 2xl:ml-16 lg:ml-14 p-1 bg-[#FAFAFA] rounded-md focus:outline-none focus:border-[#099A40] resize-none"
    required
    rows={4} // You can adjust the number of rows as needed
  ></textarea>
</div>


        {/* Submit Button */}
        <div className="w-full md:w-[30%] mb-10">
          <input 
            type="submit" 
            value="SEND MESSAGE" 
            className="w-[70%] py-3 px-2  bg-[#000000] text-white font-semibold ml-14 rounded-sm hover:bg-[#20aa20] transition duration-300 cursor-pointer"
          />
        </div>
      </form>
      <div className="mx-auto container flex justify-center">
  <h1 className='font-poppins text-[#66666] border-2 border-dotted p-2 border-gray-300  mb-[100px] '>NOTE: Contact Form doesn't Work In the Preview, Actual Working Files are Included in the Downloadable Package.</h1>
  </div>

  </div>
  
  <div className="relative text-right max-w-[100vw]  mx-auto">
      <div className="overflow-hidden bg-none">
        <iframe
          className="w-full h-[800px] border-0"
        
          src="https://maps.google.com/maps?width=1975&height=446&hl=en&q=University of Oxford&t=&z=13&ie=UTF8&iwloc=B&output=embed"
          title="Google Map"
        ></iframe>
        <a href="https://sprunkin.com" className="hidden">Sprunki Mods</a>
      </div>
    </div>
    </>
  );
};

export default ContactForm;
