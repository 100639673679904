import React from 'react'
import rahul from '../../assets/Teams/rahul.png'
import sunil from '../../assets/Teams/sunil.png'
import rakesh from '../../assets/Teams/rakesh.png'
import { TeamCard } from '../../Components/Teams/TeamCard'
import TeamBanner from '../../assets/Teams/TeamBannerBg.jpg'



const Teamdetails=[
                   {rev:false,bold:"Rahul", img:rahul,heading:'Rahul Pandey',text:" started out as a journalist in 1993 and briefly worked in the software industry before moving into research two decades ago. He co-founded the social media team of the Indian National Congress. He founded Linier Dataquest in 2017 as India’s most robust data-driven election winning machine."},
                   {rev:true,img:sunil,bold:"Sunil", heading:'Sunil Semwal',text:' holds is an MS degree in Computer Science and started his career as a programmer for mainframe computers in the finance and insurance sector 19 years ago. He co-founded Linier Dataquest with Rahul in late 2017 and built the AI-driven targeting engine adfirst.in and also the media tracking system in brandscore.in'},
                   {rev:false,img:rakesh,bold:"Rakesh",heading:'Rakesh Kardam',text:' holds a Masters Degree in journalism and has been in the research space for about 18 years. His journey in the research industry started with the first VidhanSabha elections in Chhattisgarh in 2002 and has led several projects in different parts of the country during his professional career.'}]

export const Team = () => {
  return (
    <>
       
       <div
      className=" h-[250px] flex items-center justify-between section bg-cover  bg-center-bottom-20 relative"
      style={{ backgroundImage: `url(${TeamBanner})` }}
    >
      <div className="bg-black bg-opacity-70 absolute inset-0"></div>
      <div className="w-full relative z-10">
        <div className=" w-full row flex justify-around items-center">
          
          <div className="">
            <h1 className="text-white text-4xl font-bold">Team</h1>
          </div>

          <div className="">
            <ul className=" flex space-x-2 text-white text-lg">
              <li>
                <a href="#" className="hover:underline">
                  Home
                </a>
              </li>
              <li className="active">/ Team</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

       <div className="md:mt-10 mt-5">
        {Teamdetails.map((item,index)=><TeamCard key={index}  bold={item.bold} img={item.img} rev={item.rev} text={item.text} heading={item.heading} />)}
       </div>
    
    </>
  )
}
