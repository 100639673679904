import React from 'react'

export const TeamCard = ({img,rev,text,heading,bold}) => {
  return (
    <>
    <div className={`lg:w-full    container   md:mx-4 2xl:px-48 xl:px-[11%] p-0 justify-around   lg:mx-2 xl:mx-auto  my-10 flex lg:flex-row   flex-col ${rev?'lg:flex-row-reverse ':''} }`} >
      <img src={img} className='md:w-full   lg:w-[488px] xl:w-[555px] md:mx-0 mx-auto lg:mx-3 md:h-auto lg:h-[280px] w-[296px] h-[148px] ' alt="" />
       <div className="   md:mt-0 mt-5     lg:mt-[-14px] xl:mt-[-1%] md:mx-0 xl:mx-2 mx-3   ">
       <h1 className='font-poppins text-[#099A40] md:text-[50px] text-[30px] font-poppins  font-extrabold'> {heading}</h1>
       <h2 className='font-poppins leading-loose   font-normal text-[#666666] text-[16px]'><span  className=' font-bold' >{bold}</span>{text}</h2>
       </div>
    </div>
    </>
  )
}
