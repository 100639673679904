import React from 'react'
import {ServiceCard} from '../../Components/Services/ServicesCard'

import TeamBanner from '../../assets/Teams/TeamBannerBg.jpg'


const Servicesdetails=[
    { heading:"MEDIA ORGANISATIONS",text:"Covering elections goes beyond predicting the winner. While accuracy is critical, we need to stand apart. We understand the dynamics, giving viewers greater insights",link:""},
  {heading:"FOR POLITICAL PARTIES",text:"We believe that political parties are in the business of winning elections. We believe that a majority of votes can be turned, if we use the right triggers. Lets play",link:""},
  {heading:"FOR INDIVIDUAL CLIENTS",text:"Even the most experienced leaders need to win to stay relevant in the political game. Understanding micro trends, planning and execution helps clients gain an advantage",link:""},
  {heading:"LEADERSHIP BRANDING",text:"Every leader has different strengths and hence needs a customised solution and plan. Our AI-based brand tracking systems helps you measure progress & helps you plan.",link:""}

]
export const Services = () => {
  return (
    <>

    
<div
      className=" h-[250px] flex items-center justify-between section bg-cover  bg-center-bottom-20 relative"
      style={{ backgroundImage: `url(${TeamBanner})` }}
    >
      <div className="bg-black bg-opacity-70 absolute inset-0"></div>
      <div className="w-full relative z-10">
        <div className=" w-full row flex justify-around items-center">
          
          <div className="">
            <h1 className="text-white text-4xl font-bold">Services</h1>
          </div>

          <div className="">
            <ul className=" flex space-x-2 text-white text-lg">
              <li>
                <a href="#" className="hover:underline">
                  Home
                </a>
              </li>
              <li className="active">/ Services</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
   <div className="flex justify-center">
    <div className="flex  flex-wrap  mt-20 mb-10 lg:mx-20 xl:mx-0 container mx-auto 2xl:px-36 lg:px-10 px-2">
     {Servicesdetails.map((item,index)=> <ServiceCard key={index} heading={item.heading} text={item.text} link={item.link}/>)}
    </div>
    </div>
    </>
  )
}
