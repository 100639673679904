import React,{useState} from 'react';
import pollfirst_logo from '../../assets/pollfirst_logo.png';
import { FaBars } from "react-icons/fa6";
import { Link } from 'react-router-dom';

const menuitems = [
  { text: "TEAM", path: "/team" },
  { text: "ETHICS", path: "/ethics" },
  { text: "SERVICES", path: "/services" },
  { text: "CONTACT US", path: "/contact" },
  { text: "LOGIN", path: "http://pollfirst.pollfirst.in/" },  
];


//changed--done
export const Navbar = () => {
      const [isOpen, setIsOpen] = useState(false); 

  const toggleMenu = () => {
    setIsOpen(!isOpen); 
  };
  return (
    <>
      <div className="w-full md:flex hidden justify-between my-4  px-24">
        <div className="w-1/2  ">
         <Link to="/"> <img src={pollfirst_logo} className="md:p-1  p-0 md:w-[20%] w-[30%] " alt="PollFirst Logo" /></Link>
        </div>
        
        <div className="menu flex mt-1    items-center">
          {menuitems.map((item, index) => (
            <Link key={index}  to={item.path} className="mx-4  text-nowrap">
              {item.text}
            </Link>
          ))}
        </div>


      </div>

      
      <div className="w-full md:hidden flex justify-around my-4 ">
        <div className="w-1/2">
        <Link to="/"><img src={pollfirst_logo} className="w-[35%] " alt="PollFirst Logo" /></Link>
        </div>

        <FaBars className='mt-4' onClick={toggleMenu}  />

        { isOpen   &&
        <div className="=mobile md:hidden right-0 absolute py-2  mt-10 bg-white menu flex flex-col   mt-1 z-50">
          {menuitems.map((item, index) => (
            <Link className=" mx-4  hover:bg-gray-300 text-left" key={index} to={item.path} >
              {item.text}
            </Link>
          ))}
        </div>}


      </div>
    </>
  );
};
