import React from 'react'
import Campaign2  from '../../assets/Campaign2.jpg'

export const CampaignManagement = () => {
  return (
    <>
    <div className="">
    <div className="my-5 md:mx-5 lg:mx-0 mx-4   flex xl:flex-row  flex-col md:flex-row justify-around">


        <img src={Campaign2}  className='xl:w-auto w-full h-[300px] 2xl:h-[900px] lg:h-[700px] md:mr-2 2xl:p-0  xl:p-3 lg:p-4 p-3 mr-0' alt="" />

         <div className="font-poppins mt-1 ">
            <h1  className='text-3xl text-center font-bold mb-5 font-poppins'>   CAMPAIGN MANAGEMNET</h1>
            <div className=" md:mx-18 md:px-3 mx-0 px-0">
            <h2 className='mt-4 text-[20px] '>The purpose of every campaign is to reach every voter with relevant messaging and try to convert every vote.</h2>
            <h2 className='mt-4 text-[20px] '>​Elections, however, are more complicated than that, as many voters are deeply committed to their present political choices.</h2>
            <h2 className='mt-4 text-[20px] '>​From state-of-the-art research & backend data management systems that looks at opportunities to swing votes, to effective communication strategies, we blend the science and the art of swinging votes</h2> 
            <h2 className='mt-4 text-[20px] '>​Our true strength, however, is our ability to connect with the micro-influencers and voters on the ground</h2>
              </div>


         </div>
  
</div>
<h3 className='lg:ml-[90%] 2xl:ml-[90%] mb-6 text-xl lg:mt-[-15px] xl:mt-[-4rem] xl:mb-20 mt-0 font-poppins font-medium'>Know more</h3>
  
      
   
    </div>
    
    
    </>
  )
}
